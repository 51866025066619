<template>
  <div>
    <AddSingleAssetForm :market="market" :key="successCount" @success="successCount++" />

    <pp-card-body v-if="successCount && !isMainnet" class="text-p-green-600">
      <div class="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 inline-block"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>

        <div class="ml-2">
          Successfully added liquidity,

          <router-link
            class="underline"
            :to="{ name: 'StakeShow', params: { inputTokenAddress: market.address } }"
            v-text="'stake the LP'"
          ></router-link>
          now
        </div>
      </div>
    </pp-card-body>
  </div>
</template>

<script>
  import Vue from 'vue'
  import AddSingleAssetForm from '@/components/forms/AddSingleAssetForm.vue'
  import { sdkChainId } from '@/app-config/network'

  export default Vue.extend({
    components: { AddSingleAssetForm },
    data() {
      return {
        successCount: 0,
      }
    },
    computed: {
      isMainnet() {
        return sdkChainId === 1
      },
    },
    inject: ['market'],
  })
</script>
