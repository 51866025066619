<template>
  <pp-form @submit.prevent="handleSubmit">
    <template v-slot="{ disabled }">
      <hr />

      <pp-card-body class="overflow-visible">
        <div class="space-y-4">
          <pp-token-amount-input
            autocomplete="off"
            :key="tokenAddress"
            :address.sync="tokenAddress"
            :address-options="market.pair"
            show-token
            v-model="amount"
            :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
          ></pp-token-amount-input>
        </div>
      </pp-card-body>

      <template v-if="details.is('resolved')">
        <hr />

        <pp-card-body class="space-y-4 overflow-visible">
          <pp-simple-data>
            <template #left>Rate</template>
            <template #right>
              <pp-rate
                :pair="[
                  {
                    unit: tokenAmount.token.symbol,
                    amount: 1,
                  },
                  {
                    unit: tokenFind(details.response.rate.token.address).symbol,
                    amount: details.response.rate.formattedAmount(),
                  },
                ]"
              ></pp-rate>
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              Slippage tolerance

              <pp-tooltip-trigger direction="top">
                The transaction will revert if there is a large, unfavourable price movement before
                it is confirmed.
              </pp-tooltip-trigger>
            </template>
            <template #right>
              <div class="font-semibold">
                <pp-formatted-number
                  data-test="slippage-tolerance"
                  :value="details.response.slippage"
                  :max-decimal="8"
                ></pp-formatted-number>
                %
              </div>
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              Price Impact

              <pp-tooltip-trigger direction="top">
                The difference between market price and estimated execution price due to trade size.
              </pp-tooltip-trigger>
            </template>
            <template #right>
              <div class="font-semibold">
                <pp-formatted-number
                  data-test="price-impact"
                  :value="100 * details.response.priceImpact"
                ></pp-formatted-number>
                %
              </div>
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>
              Swap Fee
              <pp-tooltip-trigger direction="top">
                A portion of each swap goes to the liquidity providers and the treasury.
              </pp-tooltip-trigger>
            </template>
            <template #right>
              <div class="font-semibold">
                <pp-formatted-number
                  data-test="swap-fee"
                  :value="details.response.swapFee.formattedAmount()"
                  :humanize="false"
                  :max-decimal="8"
                ></pp-formatted-number>

                <pp-token-symbol
                  class="ml-2"
                  :address="details.response.swapFee.token.address"
                ></pp-token-symbol>
              </div>
            </template>
          </pp-simple-data>

          <pp-simple-data>
            <template #left>Approx. share of pool</template>
            <template #right>
              <div class="font-semibold">
                <pp-formatted-number
                  data-test="approx-share"
                  :value="100 * details.response.shareOfPool"
                  :max-decimal="8"
                ></pp-formatted-number>
                %
              </div>
            </template>
          </pp-simple-data>
        </pp-card-body>
      </template>

      <pp-card-actions>
        <pp-token-approve-container
          :spender-address="market.spenderAddress"
          :spending-amount="tokenAmount"
          :key="`${market.spenderAddress}-${tokenAddress}`"
        >
          <template v-slot:default="{ approved }">
            <pp-btn
              v-if="approved"
              size="xl"
              class="flex w-full"
              type="submit"
              :disabled="disabled"
              data-test="btn"
              :loading="details.is('loading') || form.is('loading')"
            >
              Add Liquidity
            </pp-btn>
          </template>
        </pp-token-approve-container>
      </pp-card-actions>
    </template>
  </pp-form>
</template>

<script>
  import Vue from 'vue'
  import Token from '@/domains/entities/Token'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { generateEtherscanTxUrl, formatNumber } from '@/assets/helpers'

  const debounceTime = 500

  export default Vue.extend({
    props: {
      market: { type: Object, required: true },
    },
    data() {
      return {
        tokenAddress: this.$props.market.pair[0],
        amount: '',
        timeout: null,
        details: createState(),
        form: createState(),
        interval: null,
      }
    },
    computed: {
      tokenAmount() {
        return new TokenAmount(Token.query().find(this.tokenAddress), this.amount || 0, false)
      },
    },
    watch: {
      tokenAmount(val) {
        this.handleTokenAmountChange(val)
      },
      '$app.state.slippage': function () {
        this.handleTokenAmountChange(this.tokenAmount)
      },
    },
    methods: {
      tokenFind(addr) {
        return Token.query().find(addr)
      },
      handleTokenAmountChange(val) {
        clearTimeout(this.timeout)
        clearInterval(this.interval)
        const slippage = this.$app.state.slippage

        if (val.formattedAmount() > 0) {
          this.timeout = setTimeout(() => {
            new PromiseHandler(
              () =>
                this.market.contract(this.$store.getters['wallet/identity']).addSingleDetails({
                  tokenAmount: val,
                }),
              this.details
            )
              .execute({ force: true })
              .then((response) => {
                this.details.response = { ...response, slippage }
                this.interval = setInterval(() => this.handleTokenAmountChange(val), 60000)
              })
          }, debounceTime)
        } else {
          this.details.state = 'idle'
        }
      },
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.market.contract(this.$store.getters['wallet/identity']).addSingle({
              tokenAmount: this.tokenAmount,
              slippage: this.$app.state.slippage,
            }),
          this.form
        )
          .execute()
          .then((response) => {
            const inputTokenNames = this.market.tokenPair.map((token) => token.name)
            this.$notification.success({
              title: 'Add liquidity',
              text: `Added ${formatNumber(this.amount)} ${inputTokenNames[0]} to ${
                this.market.token.name
              }.`,
              action: {
                url: generateEtherscanTxUrl(response.hash),
                urlText: 'View on explorer',
              },
            })

            this.$emit('success', response)
          })
          .catch(() => {
            this.$notification.error({
              title: 'Add liquidity',
              text: `Unable to add liquidity to ${this.market.token.name}`,
            })
          })
      },
    },
  })
</script>
